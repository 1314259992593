<template>
  <div class="pg-done">
    <div v-if="success" style="max-width:420px;width:100%">
      <div class="pg-done-title">결제완료</div>
      <div class="lp-row">
        <div class="lp-title">서비스 명</div>
        <div class="lp-content">{{ service.name }}</div>
      </div>
<!--      <div class="lp-row">
        <div class="lp-title">URL</div>
        <div class="lp-content">{{ service.key }}</div>
      </div>-->
      <div class="lp-row">
        <div class="lp-title">관리자 이름</div>
        <div class="lp-content">{{ user.name }}</div>
      </div>
      <div class="lp-row">
        <div class="lp-title">관리자 이메일</div>
        <div class="lp-content">{{ user.email }}</div>
      </div>
      <div class="lp-row">
        <div class="lp-title">관리자 연락처</div>
        <div class="lp-content">{{ user.phone }}</div>
      </div>

      <!--<div class="lp-box">
        <div class="lp-box-title">
          <div>신청 테마 및 요금제</div>
          <div>가격</div>
        </div>
        <div class="lp-box-content">
          <div class="flex" style="justify-content: space-between">
            <div>
              <div class="lp-theme-name">{{ theme.name }}</div>
              <div>Start-up 요금제</div>
            </div>
            <div style="text-align:right">
              <div class="lp-price-origin">{{ card.price|currency }}</div>
              <div>{{ card.price|currency }}</div>
            </div>
          </div>
        </div>
      </div>-->
      <!--<div class="lp-desc" style="margin-top:4px" v-if="card.price_type === 1">* 해당 상품은 매월 정기결제되는 상품입니다</div>-->

      <div class="lp-box">
        <div class="lp-box-content flex" style="justify-content: space-between">
          <div>합계</div>
          <div>{{ card.price|currency }}</div>
        </div>
        <div class="lp-line"></div>
        <div class="lp-box-content flex" style="justify-content: space-between;align-items:flex-end">
          <div>결제금액</div>
          <div class="lp-price-account">{{ card.price|currency }}</div>
        </div>
      </div>

      <div class="lp-box">
        <div class="lp-box-title">
          <div>결제방법</div>
          <div>결제일</div>
        </div>
        <div class="lp-box-content flex" style="justify-content: space-between">
          <div>
            <div>카드결제</div>
            <div>{{ card.code + ' / '  + card.num }}</div>
          </div>
          <div style="text-align: right">
            <div>{{ card.account_date }}</div>
            <!--<div class="lp-desc" v-if="card.price_type === 1">{{ card.next_date }}(다음 결제 예정일)</div>-->
          </div>
        </div>
      </div>

    </div>


    <div v-else>
      <div class="pg-done-title">결제실패</div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "pg-done",
    data() {
      return {
        success: true,
        'user': {
          'email': '',
          'name': '',
          'phone': ''
        },
        'service': {
          'name': '',
          'key': ''
        },
        'theme': {
          'name': ''
        },
        'card': {
          'num': '',
          'code': '',
          'price': '',
          'account_date': '',
          'next_date': ''
        }
      }
    },

    created() {
      if(this.$route.query.result === 'error')
        this.success = false;
      else {
        this.$axios.get('/public/launchpack/service/billing_done?tid='+this.$route.query.tid).then( res => {
          if(res.status === 200) {
            this.user = res.data.user;
            this.service = res.data.service;
            this.theme = res.data.theme;
            this.card = res.data.card;
          }
        });
      }
    }
  }
</script>

<style scoped>
  .pg-done {
    padding: 110px 20px 80px 20px;
    display: flex;
    justify-content: center;
  }

  .logo {
    width: 320px;
  }

  .pg-done-title {
    font-size: 18px;
    color: #393e46;
    font-weight: bold;
    margin-bottom: 48px;
    text-align: center;
  }

  .lp-row {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }

  .lp-title {
    color: #989898;
    line-height: 44px;
    font-size: 14px;
    width: 30%;
    text-align: left;
  }

  .lp-content {
    background-color: #fafafa;
    border-radius: 3px;
    line-height: 44px;
    height: 44px;
    color: #393e46;
    font-size: 13px;
    padding: 0 8px;
    width: 70%;
  }

  .lp-box {
    margin-top: 24px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
  }

  .lp-box-title {
    background-color: #fafafa;
    color: #989898;
    font-size: 13px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
  }

  .lp-box-content {
    padding: 12px;
    font-size: 13px;
  }

  .lp-line {
    width: calc(100% - 24px);
    margin-left: 12px;
    height: 1px;
    background-color: #e6e6e6;
  }

  .lp-price-origin {
    text-decoration: line-through;
    color: #989898;
  }

  .lp-price-account {
    font-size: 18px;
    font-weight: bold;
  }

  .lp-theme-name {
    font-size: 14px;
    font-weight: bold;
  }

  .lp-desc {
    font-size: 13px;
    color: #989898;
  }

</style>
